<template>
  <v-form
    ref="form"
    lazy-validation
    class="app-form"
    @submit.prevent="onSubmit"
  >
    <v-expansion-panels
      v-model="panel"
      class="app-expansion-panels"
    >
      <v-expansion-panel @click.stop="changeCollapse">
        <v-expansion-panel-header class="app-expansion-panel-header mb-0">
          <h4 class="text">
            <v-icon>filter_alt</v-icon>
            <span>Filtrar Pagamentos</span>
          </h4>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="app-expansion-panel-content">
          <v-row class="mt-3">
            <v-col
              :lg="3"
              :md="3"
              :sm="3"
              :xs="12"
            >
              <v-text-field
                v-model="table.paginate.input.solicitationId"
                :clearable="true"
                label="Número do Pedido "
                placeholder="Ex:. 09832"
                outlined
                dense
              />
            </v-col>
            <v-col
              :lg="3"
              :md="3"
              :sm="3"
              :xs="12"
            >
              <v-autocomplete
                v-model="table.paginate.input.companyId"
                :items="companies"
                :clearable="true"
                :loading="companiesIsLoading"
                item-text="name"
                item-value="id"
                label="Selecione uma empresa"
                single-line
                outlined
                dense
              />
            </v-col>
            <v-col
              :lg="3"
              :md="3"
              :sm="3"
              :xs="12"
            >
              <v-select
                v-model="table.paginate.input.status"
                :items="statuses"
                :clearable="true"
                item-text="name"
                item-value="id"
                label="Selecione um status"
                single-line
                outlined
                dense
              />
            </v-col>
            <v-col
              :lg="3"
              :md="3"
              :sm="3"
              :xs="12"
            >
              <v-text-field
                v-model="table.paginate.input.value"
                :clearable="true"
                label="Valor do pagamento"
                placeholder="Ex:. 200"
                outlined
                dense
              />
            </v-col>
          </v-row>
          <v-divider />
          <div class="mt-3 d-flex">
            <v-spacer />
            <v-btn
              outlined
              color="error"
              type="button"
              class="mr-2"
              @click.prevent.stop="clearFilter"
            >
              <v-icon size="14">
                delete
              </v-icon>Limpar Filtros
            </v-btn>
            <v-btn
              depressed
              color="success"
              type="submit"
            >
              Filtrar
            </v-btn>
          </div>

          <section
            v-if="table.isLoading"
            class="loading"
          >
            <v-progress-circular
              indeterminate
              color="success"
            />
          </section>
          <div id="endForm" />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-form>
</template>

<script>
  import { mapState, mapActions } from 'vuex';

  import EmpresaService from '@/services/empresa.service';

  export default {
    data: () => ({
      panel: null,
      searchCompanyInput: null,
      companies: [],
      companiesIsLoading: false,
      statuses: [
        'PENDENTE',
        'CONFIRMADO',
        'CANCELADO',
        'REJEITADO'
      ]
    }),
    computed: {
      ...mapState('pagamento', ['table'])
    },
    async mounted(){
      await this.loadCompanies()
    },
    methods: {
      async onSubmit() {
        await this.search();
        this.$vuetify.goTo('#endForm');
      },
      clearFilter() {
        this.clear();
        this.search();
      },
      async loadCompanies(value = null) {
        try {
          const paginate = {
            input: { name: value },
            inputPage: {
              page: 1,
              size: 500,
              order: 'name',
              descendingOrder: false
            }
          }

          this.companiesIsLoading = true

          const { items } = await this.fetchCompanies(paginate)

          this.companies = items

          this.companiesIsLoading = false
        } catch (error) {
          this.companiesIsLoading = false
        }
      },
      async fetchCompanies(paginate) {
        return await EmpresaService.page(paginate).toPromise();
      },
      changeCollapse() {},
      ...mapActions('pagamento', { search: 'SEARCH' }),
      ...mapActions('pagamento', { clear: 'CLEAR' }),
      ...mapActions('pagamento', { changePage: 'CHANGE_PAGE' })
    },
  };
</script>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"grid-list-xl":""}},[_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-flex',{attrs:{"lg12":"","sm12":"","xs12":""}},[_c('Search'),_c('div',{staticClass:"mb-3"},[_c('GeneratePDF',{attrs:{"head":_vm.table.header,"itens":_vm.list,"title":'Pagamentos',"items-per-page":8}}),_c('GenerateExcel',{attrs:{"head":_vm.table.header,"itens":_vm.list,"title":'Pagamentos'}})],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.table.header,"items":_vm.table.content,"item-key":'id',"loading":_vm.table.isLoading,"items-per-page":_vm.table.paginate.inputPage.size,"options":_vm.pagination,"server-items-length":_vm.table.total,"footer-props":{ 'items-per-page-options': [10, 20, 30, 40, 50, 100],showFirstLastPage: true, showCurrentPage: true }},on:{"update:options":function($event){_vm.pagination=$event},"click:row":_vm.goToForm},scopedSlots:_vm._u([{key:"item.value",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.value))+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("statusPagamento")(item.status))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{
              name: 'dashboard-pagamentos-detail',
              params: { id: item.id }
            },"title":"Visualizar"}},[_c('v-icon',[_vm._v("visibility")])],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
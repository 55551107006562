<template>
  <v-container grid-list-xl>
    <v-layout
      row
      wrap
    >
      <v-flex
        lg12
        sm12
        xs12
      >
        <Search />
        <div class="mb-3">
          <GeneratePDF
            :head="table.header"
            :itens="list"
            :title="'Pagamentos'"
            :items-per-page="8"
          />
          <GenerateExcel
            :head="table.header"
            :itens="list"
            :title="'Pagamentos'"
          />
        </div>
        <v-data-table
          class="elevation-1"
          :headers="table.header"
          :items="table.content"
          :item-key="'id'"
          :loading="table.isLoading"
          :items-per-page="table.paginate.inputPage.size"
          :options.sync="pagination"
          :server-items-length="table.total"
          :footer-props="{ 'items-per-page-options': [10, 20, 30, 40, 50, 100],showFirstLastPage: true, showCurrentPage: true }"
          @click:row="goToForm"
        >
          <template v-slot:item.value="{ item }">
            {{ item.value | currency }}
          </template>

          <template v-slot:item.status="{ item }">
            {{ item.status | statusPagamento }}
          </template>

          <template v-slot:item.actions="{ item }">
            <router-link
              :to="{
                name: 'dashboard-pagamentos-detail',
                params: { id: item.id }
              }"
              title="Visualizar"
            >
              <v-icon>visibility</v-icon>
            </router-link>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
  import { mapActions, mapState } from 'vuex';
  import Search from './Search';
  import GenerateExcel from '@/components/GenerateExcel.vue';
  import GeneratePDF from '@/components/GeneratePDF.vue';

  export default {
    components: {
      Search,
      GenerateExcel,
      GeneratePDF,
    },
    data: () => ({
      pagination: null,
      list:null
    }),
    computed: {
      ...mapState('pagamento', ['table'])
    },
    watch: {
      pagination: {
        handler() {
          this.changePagination();
        },
        deep: true
      },
      'table.content': function (newVal, oldVal) {
        this.paymentList()

      }
    },
    destroyed() {
      this.clear();
    },
    methods: {
      changePagination() {
        const { sortBy, sortDesc, page, itemsPerPage } = this.pagination;
        const paginate = {
          ...this.table.paginate,
          inputPage: {
            page: page,
            size: itemsPerPage,
            order: sortBy.length > 0 ? sortBy[0] : 'payment.id',
            descingOrder: sortDesc.length > 0 ? sortDesc[0] : true
          }
        };
        this.changePage(paginate);
        this.search();
      },
      goToForm({ id }) {
        this.$router.push({
          name: 'dashboard-pagamentos-detail',
          params: { id }
        });
      },
      paymentList() {
        const { content } = this.table;
        const formattedList = content.map(item => ({
          'Pagamento ID': item.id,
          'Número Pedido': item.solicitation.id,
          'Empresa': item.solicitation.company.name,
          'Status': item.status,
          'Valor': item.value
        }));

        this.list = formattedList;
      },
      ...mapActions('pagamento', { search: 'SEARCH' }),
      ...mapActions('pagamento', { clear: 'CLEAR' }),
      ...mapActions('pagamento', { changePage: 'CHANGE_PAGE' })
    },
  };
</script>
